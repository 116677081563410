import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { parseAndFormateDate } from "../../utils/common";
import { AttachmentOutlined } from "@mui/icons-material";
import { IssueMediaModal } from "./issueMediaModal";

const IssueMsgView = ({ x }: any) => {
  const [media, setMedia] = useState(false);

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
        mb={1.5}
      >
        <Stack>
          <Stack direction="row" alignItems="center">
            <Box>
              <Box
                sx={{
                  backgroundColor: "#eeeeee",
                  borderRadius: 3,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                }}
                px={2}
                py={1}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.5,
                  }}
                >
                  <Typography sx={{ whiteSpace: "pre-wrap" }} variant="body2">
                    {x?.text}
                  </Typography>
                  {x.images?.length > 0 ? (
                    <Tooltip title="Attachments">
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => setMedia(true)}
                      >
                        <AttachmentOutlined />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </Box>
                <Typography
                  sx={{ fontSize: "12px !important" }}
                  color="#718096"
                  textAlign="right"
                >
                  {parseAndFormateDate(x?.updated_at_datetime)} &nbsp;-&nbsp;
                  <span style={{ fontWeight: "bold" }}>
                    {x.updated_by?.split("@")[0]}
                  </span>
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor:
                    x.visibility === "internal" ||
                    x.visibility === "techsupport"
                      ? "#FBD38D"
                      : "#C6F6D5",
                  borderBottomLeftRadius: "3rem",
                  borderBottomRightRadius: "3rem",
                }}
              >
                <Typography
                  variant="body2"
                  fontSize="10px !important"
                  sx={{ textAlign: "center" }}
                >
                  {x?.visibility === "internal"
                    ? "Sent to: Internal Team"
                    : x?.visibility === "techsupport"
                    ? "Sent to: Tech Team"
                    : "Sent to: Pharmacy"}
                </Typography>
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Box>
      {media ? (
        <IssueMediaModal
          open={media}
          onClose={() => setMedia(false)}
          activeLocationId=""
          uploadType="issue"
          uploadedFiles={x.images}
          setUploadedFiles={null}
          mode="view"
        />
      ) : null}
    </>
  );
};

export default IssueMsgView;
