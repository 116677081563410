import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable, { FilterType } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { commonTableOptions } from "../../../constants";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import { handleError } from "../../../utils/common";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import UpdateCertModal from "../../Providers/updateCertModal";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthenticatedUser } from "../../../interfaces/user.model";
import useUserInfo from "../../../hooks/useUserInfo";
import { differenceInDays } from "date-fns";
import CancelIcon from "@mui/icons-material/Cancel";
import A4Icon from "../../../assets/images/a4.png";
import ChangeOnboardingModel from "./changeOnboardingModel";
import ManageDevicesModal from "./manageDevicesModal";

const QrTerminals = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const [updateCert, setUpdateCert] = useState(false);
  const [activeLocationId, setActiveLocationId] = useState<string>("");
  const location = useLocation();
  const params: any = new URLSearchParams(location.search);
  const terminalId = params.get("terminal_id");
  const navigate = useNavigate();
  const user: AuthenticatedUser = useUserInfo();
  const [filterBy, setFilterBy] = useState("");
  const [filteredData, setFilteredData] = useState<any>([]);
  const [showChangeOnboarding, setShowChangeOnboarding] = useState(false);
  const [showManageDevices, setShowManageDevices] = useState(false);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    const getData = async () => {
      let apiUrl = "analytics/digital-payments/providers";
      if (terminalId) {
        apiUrl = `${apiUrl}?terminal_id=${terminalId}`;
      }
      setLoading(true);
      try {
        const result = await privateInstance.get(`${API_VERSION.V2}/${apiUrl}`);
        if (result?.data?.status === API_RESULT.SUCCESS) {
          setData(result?.data?.response?.qr_providers);
          setFilterBy("all");
        }
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.key]);

  useEffect(() => {
    if (filterBy === "blacklisted") {
      setFilteredData(data.filter((x: any) => x.qr_status !== "active"));
    } else if (filterBy === "old") {
      setFilteredData(
        data.filter(
          (x: any) =>
            differenceInDays(new Date(), new Date(String(x.created_at))) > 30
        )
      );
    } else if (filterBy === "new") {
      setFilteredData(
        data.filter(
          (x: any) =>
            differenceInDays(new Date(), new Date(String(x.created_at))) < 14
        )
      );
    } else {
      setFilteredData(data);
    }
  }, [filterBy, data]);

  const cols = [
    {
      name: "location_id",
      label: "Location ID",
      options: {
        filter: false,
        customBodyRender: (value: any) => {
          return (
            <Typography minWidth={160} variant="body2">
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "pharmacy_name",
      label: "Pharmacy Name",
      options: {
        filter: true,
        filterType: "textField" as FilterType,
        customBodyRender: (value: any) => {
          return (
            <Typography minWidth={160} variant="body2">
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "terminal_phone_no",
      label: "Phone No",
      options: {
        filter: false,
      },
    },
    {
      name: "location_id",
      label: "Location ID",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "qr_partner",
      label: "QR Partner",
      options: {
        filter: false,
        searchable: false,
      },
    },
    {
      name: "qr_status",
      label: "QR Status",
      options: {
        filter: true,
        filterList: params.get("filter") ? [params.get("filter")] : [],
        searchable: false,
        customBodyRender: (value: any) => {
          return (
            <>
              {value === "pending" ? (
                <Chip
                  size="small"
                  label="PENDING"
                  sx={{ backgroundColor: "#FBD38D", padding: "6px" }}
                />
              ) : value === "active" ? (
                <Chip
                  size="small"
                  label="ACTIVE"
                  sx={{ backgroundColor: "#C6F6D5", padding: "6px" }}
                />
              ) : (
                <Chip
                  size="small"
                  label="INACTIVE"
                  sx={{
                    backgroundColor: "#C53030",
                    color: "#FFF",
                    padding: "6px",
                  }}
                />
              )}
            </>
          );
        },
      },
    },
    {
      name: "terminal_id",
      label: "TERMINAL ID",
      options: {
        filter: false,
      },
    },
    {
      name: "soundbox_provider",
      label: "Soundbox",
      options: {
        filter: true,
        searchable: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <Tooltip
              title={data[tableMeta.rowIndex]?.soundbox_id || "Link Soundbox"}
            >
              <Typography
                sx={{ cursor: "pointer" }}
                color="primary"
                onClick={() => {
                  setShowManageDevices(true);
                  setSelected(data[tableMeta.rowIndex]);
                }}
                variant="body2"
              >
                {value || "NA"}
              </Typography>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "qr_onboarding_type",
      label: "Onboarding Model",
      options: {
        filter: true,
        searchable: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              {data[tableMeta.rowIndex]?.qr_status === "active" &&
              value === "merchant" ? (
                <Typography
                  variant="body2"
                  color="primary"
                  onClick={() => {
                    setActiveLocationId(data[tableMeta.rowIndex]?.location_id);
                    setShowChangeOnboarding(true);
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  {value}
                </Typography>
              ) : (
                <Typography variant="body2">{value}</Typography>
              )}
            </>
          );
        },
      },
    },
    {
      name: "city",
      label: "City",
      options: {
        filter: true,
      },
    },
    {
      name: "pincode",
      label: "Pincode",
      options: {
        filter: true,
        filterType: "textField" as FilterType,
      },
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value: any) => {
          return (
            <Typography minWidth={100} variant="body2">
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "static_qr_link",
      label: "Static QR Link",
      options: {
        filter: false,
        searchable: false,
        display: false,
        download: true,
      },
    },
    {
      name: "",
      label: "Actions",
      options: {
        donwload: false,
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <Stack direction="row">
              <Box
                textAlign="center"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setActiveLocationId(data[tableMeta.rowIndex]?.location_id);
                  setUpdateCert(true);
                }}
              >
                <PermIdentityIcon />
                <Typography
                  sx={{
                    fontSize: "10px !important",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  KYC {data[tableMeta.rowIndex]?.qr_status}
                </Typography>
              </Box>
              <Box pl={1} textAlign="center">
                <IconButton
                  color="primary"
                  disabled={!data[tableMeta.rowIndex]?.static_qr_link}
                  onClick={() => {
                    window.open(
                      data[tableMeta.rowIndex].static_qr_link,
                      "_blank"
                    );
                  }}
                >
                  <Tooltip title="Download QR">
                    <QrCode2Icon />
                  </Tooltip>
                </IconButton>
              </Box>
              <Box pl={1} textAlign="center">
                <IconButton
                  color="primary"
                  disabled={
                    !data[tableMeta.rowIndex]?.static_qr_link_map?.a4_pdf_url
                  }
                  onClick={() => {
                    window.open(
                      data[tableMeta.rowIndex].static_qr_link_map?.a4_pdf_url,
                      "_blank"
                    );
                  }}
                >
                  <Tooltip title="Download A4 PDF">
                    <img
                      alt="a4"
                      style={{
                        width: "24px",
                        opacity: !data[tableMeta.rowIndex]?.static_qr_link_map
                          ?.a4_pdf_url
                          ? 0.5
                          : 1,
                      }}
                      src={A4Icon}
                    />
                  </Tooltip>
                </IconButton>
              </Box>
            </Stack>
          );
        },
      },
    },
  ];

  const options: any = {
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    ...commonTableOptions,
    searchPlaceholder: "Location ID/Terminal ID/Name/Phone No",
    download: [
      "ravi@medpay.in",
      "arun@medpay.in",
      "amit@medpay.in",
      "anuj@medpay.in",
      "onboardings@medpay.in",
      "joys.saji@medpay.in",
      "jai.srivastava@medpay.in",
    ].includes(String(user?.user_id))
      ? true
      : false,
    downloadOptions: {
      filename: "Terminals_" + new Date().toLocaleDateString(),
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: true,
      },
    },
  };

  return (
    <Box>
      <Stack
        mb={1.5}
        direction="row"
        justifyContent={terminalId ? "space-between" : "end"}
      >
        {terminalId ? (
          <Chip
            label={`Terminal ID: ${terminalId}`}
            onDelete={() => {
              navigate("/digital-payment/qr-terminals");
            }}
            deleteIcon={<CancelIcon fontSize="small" />}
            variant="filled"
            color="primary"
          />
        ) : null}

        <Stack direction="row" spacing={1.5}>
          {[
            { key: "new", label: "New Pharmacy", title: "In Last 2 Weeks" },
            { key: "old", label: "Old Pharmacy", title: "1 Month Old" },
            { key: "blacklisted", label: "Blacklisted", title: "" },
          ].map(
            (
              item: { key: string; label: string; title: string },
              index: number
            ) => {
              return (
                <Tooltip key={index} title={item.title}>
                  <Chip
                    label={item.label}
                    onClick={() => setFilterBy(item.key)}
                    onDelete={() => setFilterBy("all")}
                    deleteIcon={
                      filterBy === item.key ? (
                        <CancelIcon fontSize="small" />
                      ) : (
                        <p></p>
                      )
                    }
                    variant={filterBy === item.key ? "filled" : "outlined"}
                    disabled={data?.length === 0}
                    color="primary"
                  />
                </Tooltip>
              );
            }
          )}
        </Stack>
      </Stack>

      <MUIDataTable
        title=""
        data={filteredData}
        columns={cols}
        options={options}
      />
      {updateCert ? (
        <UpdateCertModal
          updateCert={updateCert}
          setUpdateCert={setUpdateCert}
          activeLocationId={activeLocationId}
          callBack={null}
        />
      ) : null}
      {showChangeOnboarding ? (
        <ChangeOnboardingModel
          activeLocationId={activeLocationId}
          showChangeOnboarding={showChangeOnboarding}
          setShowChangeOnboarding={setShowChangeOnboarding}
        />
      ) : null}
      {showManageDevices ? (
        <ManageDevicesModal
          open={showManageDevices}
          onClose={() => setShowManageDevices(false)}
          rowData={selected}
          tableData={data}
          setTableData={setData}
        />
      ) : null}
    </Box>
  );
};

export default QrTerminals;
