import * as React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useUserInfo from "../../hooks/useUserInfo";
import useUserPermissions from "../../hooks/useUserPermissions";
import { AuthenticatedUser } from "../../interfaces/user.model";
import { errorToast } from "../../utils/common";

const RequireAuth = () => {
  const user: AuthenticatedUser = useUserInfo();
  const userPermissions = useUserPermissions();
  const location = useLocation();

  let allowedPaths: string[] = [];
  //add dummy payment route for dev only
  if (process.env.REACT_APP_STAGE !== "production") {
    allowedPaths.push("/payment-gateway");
  }
  // add items from user auth permissions
  userPermissions?.forEach((menu: any) => {
    if (menu.child !== "") {
      allowedPaths.push(
        `/${menu.parent?.split(" ")?.join("-")?.toLowerCase()}/${menu.child
          ?.split(" ")
          ?.join("-")}`
      );
    } else {
      allowedPaths.push(
        `/${menu.parent?.split(" ")?.join("-")?.toLowerCase()}`
      );
    }
    return allowedPaths;
  });
  //handle admin section order by order id page
  let currentPath = location.pathname;
  if (currentPath.includes("settings/admin-panel/")) {
    currentPath = currentPath.split("admin-panel/")[0] + "admin-panel";
  }
  //show alert for not allowed page
  if (!allowedPaths.includes(currentPath) && user?.access_token) {
    errorToast("You don't have permission to access that page");
  }

  return user?.access_token && allowedPaths.includes(currentPath) ? (
    <Outlet />
  ) : (
    <Navigate
      //to={`/login?redirectUrl=${location.pathname}${location.search}`}
      to={user?.access_token ? "/dashboard" : "/login"}
      state={{ from: location }}
      replace
    />
  );
};

export default RequireAuth;
