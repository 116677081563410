import {
  Autocomplete,
  Box,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { instance } from "../../utils/api";
import { errorToast } from "../../utils/common";

const SearchMed = () => {
  const [value, setValue] = React.useState<any | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [data, setData] = useState([]);
  const [fData, setFData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await instance.get(
          `https://medpay-public.s3.ap-south-1.amazonaws.com/companies_skus_main.json`
        );
        setData(result?.data || []);
      } catch (err) {
        errorToast("something went wrong");
      }
    };

    getData();
  }, []);

  //filter and display
  useEffect(() => {
    if (data.length > 0 && inputValue.length > 2) {
      setFData(
        data.filter((x: any) => x.name?.toLowerCase()?.includes(inputValue))
      );
    } else {
      setFData([]);
    }
  }, [data, inputValue]);

  return (
    <div>
      <Autocomplete
        ListboxProps={{ style: { maxHeight: 510 } }}
        disablePortal
        value={value}
        onChange={(event: any, newValue: string | null) => {
          setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controlled-states-demo"
        options={fData}
        sx={{ margin: "20px auto", width: { xs: "90%", md: "660px" } }}
        renderOption={(props: any, option: any) => {
          const id = props.id?.split("-")[4];
          return (
            <li {...props} key={option.id}>
              <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                sx={{ pb: 1, borderBottom: "1px solid #eeeeee" }}
              >
                <Stack width="60%">
                  {Number(id) === 0 ? (
                    <Typography pb={1} variant="h5">
                      NAME
                    </Typography>
                  ) : null}
                  <Typography variant="h6" color="primary">
                    {option.name}
                  </Typography>
                  <Typography variant="body2">{option.manufacturer}</Typography>
                </Stack>
                <Stack width="14%">
                  {Number(id) === 0 ? (
                    <Typography pb={1} variant="h5">
                      PACK
                    </Typography>
                  ) : null}
                  <Typography>{option.packaging}</Typography>
                </Stack>
                <Stack alignItems="center" width="13%">
                  {Number(id) === 0 ? (
                    <Typography pb={1} variant="h5">
                      PTR
                    </Typography>
                  ) : null}
                  <Typography>&#8377;{option.ptr}</Typography>
                </Stack>
                <Stack alignItems="end" width="13%">
                  {Number(id) === 0 ? (
                    <Typography pb={1} variant="h5">
                      MRP
                    </Typography>
                  ) : null}
                  <Typography>&#8377;{option.mrp}</Typography>
                </Stack>
              </Box>
            </li>
          );
        }}
        renderInput={(params) => <TextField {...params} label="Medicine" />}
        noOptionsText={"No data found"}
        getOptionLabel={(option: any) => option.name}
      />
      {value ? (
        <Box sx={{ margin: "auto" }} width={{ xs: "100%", md: "660px" }}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            sx={{ backgroundColor: "#0058FF", p: { xs: 1.5, md: 3 } }}
            gap={2}
          >
            <Stack alignItems="center">
              <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 128 128"
                width="120px"
                height="120px"
                fill="#fff"
              >
                <path d="M61.748 70.68h-4.914v-4.914a1.749 1.749 0 0 0-1.75-1.75h-8.649a1.75 1.75 0 0 0-1.75 1.75v4.914h-4.913a1.75 1.75 0 0 0-1.75 1.75v8.648a1.75 1.75 0 0 0 1.75 1.75h4.913v4.913a1.75 1.75 0 0 0 1.75 1.75h8.649a1.749 1.749 0 0 0 1.75-1.75v-4.913h4.914a1.75 1.75 0 0 0 1.75-1.75V72.43a1.75 1.75 0 0 0-1.75-1.75zM60 79.328h-4.916a1.75 1.75 0 0 0-1.75 1.75v4.913h-5.149v-4.913a1.75 1.75 0 0 0-1.75-1.75h-4.913V74.18h4.913a1.751 1.751 0 0 0 1.75-1.75v-4.914h5.149v4.914a1.751 1.751 0 0 0 1.75 1.75H60z" />
                <path d="M97.694 93.58H82.223V45.986a8.531 8.531 0 0 0-6.712-8.3l-1.93-.414a5.014 5.014 0 0 1-3.945-4.872v-4.633h3.975a1.75 1.75 0 0 0 1.75-1.75V14.146a1.75 1.75 0 0 0-1.75-1.75h-45.7a1.751 1.751 0 0 0-1.75 1.75v11.871a1.751 1.751 0 0 0 1.75 1.75h3.975V32.4a5.013 5.013 0 0 1-3.944 4.877l-1.931.414a8.53 8.53 0 0 0-6.712 8.3v61.129a8.5 8.5 0 0 0 8.488 8.489h45.948a8.483 8.483 0 0 0 3.379-.712 10.919 10.919 0 0 0 3.847.708h16.733a11.01 11.01 0 0 0 0-22.02zM22.8 59.924h55.923v33.66H22.8zM29.658 15.9h42.2v8.371h-42.2zm-2.917 25.208 1.93-.413a8.529 8.529 0 0 0 6.712-8.3v-4.628h30.753V32.4a8.53 8.53 0 0 0 6.713 8.3l1.929.413a5.016 5.016 0 0 1 3.945 4.878v10.433H22.8V45.986a5.015 5.015 0 0 1 3.941-4.878zM22.8 107.115V97.084h50.134a10.942 10.942 0 0 0 0 15.02h-45.15a4.994 4.994 0 0 1-4.984-4.989zm50.655-2.525a7.528 7.528 0 0 1 7.51-7.51h6.622v15.02h-6.626a7.519 7.519 0 0 1-7.51-7.51zm29.563 5.311a7.5 7.5 0 0 1-5.32 2.2h-6.615V97.08h6.611a7.517 7.517 0 0 1 5.32 12.821z" />
              </svg>
            </Stack>
            <Stack justifyContent="end">
              <Typography color="#fff" variant="h4">
                {" "}
                {value.name}
              </Typography>
              <Typography color="#fff" variant="body2">
                {" "}
                {value.packaging} | {value.manufacturer}
              </Typography>
            </Stack>
            <Stack>
              <Typography
                variant="body2"
                sx={{
                  backgroundColor: "#4CAF50",
                  textAlign: { xs: "left", md: "center" },
                  mb: 1,
                  width: "fit-content",
                  px: 1,
                }}
              >
                {" "}
                {value.medicineSubSectionType}
              </Typography>
              <Typography color="#fff" variant="body2">
                {" "}
                {value.medicineCategory}
              </Typography>
            </Stack>
          </Stack>
          <Stack sx={{ p: { xs: 2, md: 0 } }} gap={1} mt={2}>
            <Box
              width="100%"
              sx={{
                boxShadow: "0 0 .25rem rgba(0,0,0,.075)",
                padding: "5px",
                borderRadius: "5px",
                mt: { xs: 1.5, md: 0 },
              }}
            >
              <Box py={1} display="flex" justifyContent="space-between">
                <Box
                  textAlign="center"
                  width="50%"
                  sx={{
                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px !important",
                      color: "#0058ff",
                    }}
                    variant="h6"
                  >
                    ID
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: "22px !important" }}>
                    {value?.id}
                  </Typography>
                </Box>
                <Box textAlign="center" width="50%">
                  <Typography
                    sx={{
                      fontSize: "12px !important",
                      color: "#0058ff",
                    }}
                    variant="h6"
                  >
                    Retailer Margin
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: "22px !important" }}>
                    {String(value?.retailerMargin)?.split(".")[0]}%
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <Box pt={1} display="flex" justifyContent="space-between">
                <Box
                  textAlign="center"
                  width="33.33%"
                  sx={{
                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                    p: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px !important",
                      color: "#0058ff",
                    }}
                    variant="h6"
                  >
                    MRP
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: "22px !important" }}>
                    &#8377;{value?.mrp}
                  </Typography>
                </Box>
                <Box
                  textAlign="center"
                  width="33.33%"
                  sx={{
                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                    p: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px !important",
                      color: "#0058ff",
                    }}
                    variant="h6"
                  >
                    PTR
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: "22px !important" }}>
                    &#8377;{value?.ptr}
                  </Typography>
                </Box>
                <Box textAlign="center" width="33.33%" sx={{ p: 2 }}>
                  <Typography
                    sx={{
                      fontSize: "12px !important",
                      color: "#0058ff",
                    }}
                    variant="h6"
                  >
                    GST ({value?.gstPercentage}%)
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: "22px !important" }}>
                    &#8377;
                    {Number((value.ptr * value.gstPercentage) / 100)?.toFixed(
                      2
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {/* <Typography>
              <strong>GST ({value.gstPercentage}%):</strong> &#8377;{value.gst}
            </Typography> */}
          </Stack>
        </Box>
      ) : null}
    </div>
  );
};

export default SearchMed;
