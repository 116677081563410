export const ActionTypes = {
  SET_USER: "SET_USER",
  SET_USER_PERMISSIONS: "SET_USER_PERMISSIONS",
  SET_PARTNERS: "SET_PARTNERS",
  SET_EDITORS: "SET_EDITORS",
  SET_ORDEREMAIL_TIME: "SET_ORDEREMAIL_TIME",
  SET_CASHLESSEMAIL_TIME: "SET_CASHLESSEMAIL_TIME",
  SET_AUDITEMAIL_TIME: "SET_AUDITEMAIL_TIME",
  SET_CUSTOMER_CART_EMAIL_TIME: "SET_CUSTOMER_CART_EMAIL_TIME",
  SET_ALL_ORDERDATA: "SET_ALL_ORDERDATA",
  SET_SEARCH_TEXT: "SET_SEARCH_TEXT",
  UPDATE_NOTIFICATIONS: "UPDATE_NOTIFICATIONS",
};
