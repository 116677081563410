export enum API_RESULT {
  SUCCESS = "success",
  FAILED = "failed",
  OK = "OK",
}

export enum USER_ROLE {
  SUPER_ADMIN = "super admin",
  ADMIN = "admin",
  MANAGER = "manager",
  EDITOR = "editor",
  PARTNER = "partner",
}

export enum SETTLEMENT_MODE {
  UPI = "upi",
  INSTANT = "instant",
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
}

export enum PAYMENT_CREDIT_STATUS {
  PAID = "paid",
  PENDING = "pending",
  PROCESSING = "processing",
  FAILED = "failed",
  NA = "na",
}

export enum REPORT_TYPE {
  AUDIT = "audit",
  ORDER = "order",
  CASHLESS = "cashless",
  CUSTOMER_CART = "customer_cart_report",
}

export enum API_VERSION {
  V1 = "v1",
  V2 = "v2",
  V3 = "v3",
}

export enum ERROR_LIMIT_MINUES {
  PACKAGE_PICKED = 180,
  COMMON = 20,
}
