import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import AddressDetail from "../../components/CreateOrder/addressDetail";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import { updateAddressSchema } from "../../schemas/providers.schema";
import { errorToast, handleError } from "../../utils/common";

const EditAddressModal = ({
  showEditAddressModal,
  setShowEditAddressModal,
  providerInfo,
  setProviderInfo,
  providersData,
  setProvidersData,
}: any) => {
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();

  const handleSubmit = async (values: any, actions: any) => {
    let payload: any = {
      location_id: providerInfo?.location_id,
      provider_id: providerInfo?.provider_id,
      update_type: "address",
      address: values?.address,
      pin_code: values?.pin,
      city: values?.city,
      state: values?.state,
      area: values?.area,
      locality: values?.locality,
      formatted_address:
        values.address +
        ", " +
        values.area +
        ", " +
        values.locality +
        ", " +
        values.city +
        ", " +
        values.state +
        " - " +
        values.pin_code,
      latitude: values?.lat,
      longitude: values?.long,
    };

    setLoading(true);

    try {
      const result = await privateInstance.put(
        `${API_VERSION.V1}/orders/provider`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setShowEditAddressModal(false);
        const resultResponse = result.data.response;
        //update provider info in modal
        setProviderInfo({
          ...providerInfo,
          formatted_address: resultResponse?.formatted_address,
          area: resultResponse?.area,
          locality: resultResponse?.locality,
          latitude: resultResponse?.latitude,
          longitude: resultResponse?.longitude,
          pin_code: resultResponse?.pin_code,
          city: resultResponse?.city,
          state: resultResponse?.state,
        });
        //end

        //update formatted address in main list
        let oldData = [...providersData];
        let newData: any = [];

        oldData.forEach((el) => {
          if (el.location_id === providerInfo?.location_id) {
            el.formatted_address = payload.formatted_address;
          }
          newData.push(el);
        });
        setProvidersData(newData);
        //end
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={showEditAddressModal}
      onClose={() => setShowEditAddressModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setShowEditAddressModal(false)}
      >
        Edit Address Details
      </CustomDialogTitle>
      <DialogContent sx={{ overflowY: "scroll" }}>
        <Formik
          initialValues={{
            address: providerInfo?.address || "",
            pin: providerInfo?.pin_code || "",
            city: providerInfo?.city || "",
            state: providerInfo?.state || "",
            area: providerInfo?.area || "",
            locality: providerInfo?.locality || "",
            lat: providerInfo?.latitude || "",
            long: providerInfo?.longitude || "",
          }}
          validationSchema={updateAddressSchema}
          onSubmit={(values: any, actions: any) => {
            actions.setSubmitting(false);
            handleSubmit(values, actions);
          }}
        >
          {(props) => {
            const {
              values,
              setFieldValue,
              handleBlur,
              errors,
              touched,
              setTouched,
            } = props;
            return (
              <Form>
                <AddressDetail
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  formData={values}
                  setTouched={setTouched}
                  serviceabilityCheck={false}
                  showLandmarkField={false}
                />
                <Stack direction="row" justifyContent="space-between">
                  <TextField
                    fullWidth
                    id="area"
                    label="Area"
                    size="small"
                    value={values.area || ""}
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    onChange={(e) => setFieldValue("area", e.target.value)}
                    onBlur={handleBlur}
                    helperText={errors.area && touched.area ? errors.area : ""}
                    error={errors.area && touched.area ? true : false}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                  <TextField
                    fullWidth
                    id="locality"
                    label="Locality"
                    size="small"
                    value={values.locality || ""}
                    sx={{ mt: 1.5 }}
                    onChange={(e) => setFieldValue("locality", e.target.value)}
                    onBlur={handleBlur}
                    helperText={
                      errors.locality && touched.locality ? errors.locality : ""
                    }
                    error={errors.locality && touched.locality ? true : false}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <TextField
                    fullWidth
                    id="lat"
                    label="Latitude"
                    size="small"
                    value={values.lat || ""}
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    onChange={(e) => setFieldValue("lat", e.target.value)}
                    // onBlur={handleBlur}
                    // helperText={errors.lat && touched.lat ? errors.lat : ""}
                    // error={errors.lat && touched.lat ? true : false}
                    // InputLabelProps={{
                    //   required: true,
                    // }}
                  />
                  <TextField
                    fullWidth
                    id="long"
                    label="Longitude"
                    size="small"
                    value={values.long || ""}
                    sx={{ mt: 1.5 }}
                    onChange={(e) => setFieldValue("long", e.target.value)}
                    // onBlur={handleBlur}
                    // helperText={errors.long && touched.long ? errors.long : ""}
                    // error={errors.long && touched.long ? true : false}
                    // InputLabelProps={{
                    //   required: true,
                    // }}
                  />
                </Stack>

                <Box textAlign="center" mt={3}>
                  <LoadingButton
                    size="medium"
                    color="primary"
                    loading={loading}
                    loadingIndicator={
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    }
                    variant="contained"
                    type="submit"
                  >
                    Update
                  </LoadingButton>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default EditAddressModal;
