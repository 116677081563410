import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  errorToast,
  handleError,
  successToast,
  transformAllOrder,
  transformMobile,
} from "../../../../utils/common";
import { Form, Formik } from "formik";
import DiscountPercent from "../Common/discountPercent";
import { ORDER_STATUS, ORDER_TYPE } from "../../../../enums/order.enum";
import { LoadingButton } from "@mui/lab";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { transferBankSchema } from "../../../../schemas/payment";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import { useDispatch, useSelector } from "react-redux";
import { setAllOrderData } from "../../../../store/actions/useActions";
import PharmacyBills from "../../../OrderDetails/pharmacyBills";

function isInvoiceAndBilAmountDifferent(orderData: any) {
  return (
    orderData?.invoice_details &&
    (orderData?.invoice_details.pharmacy_bill_amount -
      orderData?.invoice_details.pharmacy_bill_amount * 0.01 >
      orderData?.invoice_details.bill_amount ||
      orderData?.invoice_details.bill_amount -
        orderData?.invoice_details.bill_amount * 0.01 >
        orderData?.invoice_details.pharmacy_bill_amount)
  );
}

const InstantPayment = ({
  orderData,
  instantBankDetails,
  lat,
  long,
  uploadedFiles,
  settlementMode,
  setInvoicePayoutModal,
  mode,
}: any) => {
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const dispatch = useDispatch();
  let ordersToManage = useSelector((state: any) => state.order.allOrderData);

  const handleSubmit = async (values: any, actions: any) => {
    let payload = {
      order_id: orderData?.order_id,
      payment_type:
        settlementMode.toUpperCase() === "UPI"
          ? "INSTANT"
          : settlementMode.toUpperCase(),
      provider_id: orderData?.merchant_details?.provider_id,
      location_id: orderData?.merchant_details?.location_id,
      discount_pct: values?.discount.toString(),
      amount: values.final_payable.toString(),
      location: `${lat},${long}`,
      notes: values?.notes,
      other_charges: values?.other_charges || "0",
    };

    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/payments/transfer`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Payment initiated");
        setInvoicePayoutModal(false);
        //update
        dispatch(
          setAllOrderData(
            transformAllOrder(
              ordersToManage,
              payload.order_id,
              result.data.response
            )
          )
        );
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          bill_amount: orderData?.invoice_details?.bill_amount || 0,
          pharmacy_bill_amount:
            orderData?.invoice_details?.pharmacy_bill_amount || 0,
          discount: orderData?.invoice_details?.discount_pct || 0,
          final_payable: 0,
          other_charges: 0,
          notes: "",
          mobile_number:
            orderData?.order_type === ORDER_TYPE.MEDICINES
              ? transformMobile(orderData?.merchant_details?.mobile)
              : "",
          gst_no:
            orderData?.order_type === ORDER_TYPE.MEDICINES
              ? orderData?.merchant_details?.gst_no &&
                orderData?.merchant_details?.gst_no !== "0"
                ? orderData?.merchant_details?.gst_no
                : ""
              : "",
        }}
        validationSchema={transferBankSchema}
        onSubmit={(values: any, actions: any) => {
          setLoading(true);
          actions.setSubmitting(false);
          handleSubmit(values, actions);
        }}
      >
        {(props: any) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            isSubmitting,
            setFieldValue,
          } = props;
          return (
            <Form>
              <Box sx={{ mt: 1.5, display: "flex", flexDirection: "row" }}>
                <TextField
                  fullWidth
                  id="bill_amount"
                  label="BILL AMOUNT"
                  size="small"
                  value={values.bill_amount}
                  sx={{
                    mt: 1.5,
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: isInvoiceAndBilAmountDifferent(
                        orderData
                      )
                        ? "#c53030"
                        : "",
                    },
                    mr: { xs: 0, md: 1 },
                  }}
                  disabled
                />
                <TextField
                  fullWidth
                  id="pharmacy_bill_amount"
                  label="PHARMACY INVOICE AMOUNT"
                  size="small"
                  value={values.pharmacy_bill_amount}
                  sx={{
                    mt: 1.5,
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: isInvoiceAndBilAmountDifferent(
                        orderData
                      )
                        ? "#c53030"
                        : "",
                    },
                    mr: { xs: 0, md: 1 },
                  }}
                  disabled
                />
              </Box>
              {isInvoiceAndBilAmountDifferent(orderData) ? (
                <p
                  style={{
                    fontSize: "12px",
                    color: "red",
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  Note: Invoice amount does not match pharmacy bill amount.
                  Please confirm before making payment.
                </p>
              ) : null}
              <Box sx={{ mt: 1.5 }} display="flex">
                <Box sx={{ width: "100%" }}>
                  <DiscountPercent
                    discount={values.discount}
                    final_payable={values.final_payable}
                    total={values.bill_amount}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                  />
                </Box>
              </Box>
              <Box sx={{ mt: 1.5, display: "flex", flexDirection: "row" }}>
                <TextField
                  fullWidth
                  id="discount_value"
                  label="DISCOUNT"
                  size="small"
                  value={Number(
                    values.bill_amount - values.final_payable
                  ).toFixed(2)}
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  disabled
                />
                <Stack width="100%">
                  <TextField
                    fullWidth
                    id="other_charges"
                    label="Other charges"
                    size="small"
                    value={values.other_charges}
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    onChange={(e) =>
                      setFieldValue("other_charges", e.target.value)
                    }
                  />
                  <p
                    style={{
                      fontSize: "12px",
                      color: "red",
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    Note: Please don’t use other charges for delivery fee
                  </p>
                </Stack>
              </Box>
              {orderData?.order_partner !== "dunzo" ? (
                <>
                  {orderData?.order_type === ORDER_TYPE.MEDICINES && (
                    <Box
                      sx={{ mt: 1.5, display: "flex", flexDirection: "row" }}
                    >
                      <TextField
                        fullWidth
                        id="mobile_number"
                        label="Mobile No"
                        size="small"
                        value={values.mobile_number || ""}
                        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                        onChange={(e: any) =>
                          setFieldValue("mobile_number", e.target.value)
                        }
                      />
                      <TextField
                        fullWidth
                        id="gst_no"
                        label="GST No"
                        size="small"
                        value={values.gst_no || ""}
                        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                        disabled
                      />
                    </Box>
                  )}
                  {mode === "create" && (
                    <>
                      <Box
                        sx={{ mt: 1.5, display: "flex", flexDirection: "row" }}
                      >
                        <TextField
                          fullWidth
                          id="account_name"
                          label="Account Name"
                          size="small"
                          value={instantBankDetails?.account_name || ""}
                          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                          disabled
                        />
                        <TextField
                          fullWidth
                          id="account_no"
                          label="Account Number"
                          size="small"
                          value={
                            instantBankDetails?.account_no
                              ? `XXXXXXX${instantBankDetails?.account_no?.slice(
                                  -4
                                )}`
                              : ""
                          }
                          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                          disabled
                        />
                      </Box>
                      <Box
                        sx={{ mt: 1.5, display: "flex", flexDirection: "row" }}
                      >
                        <TextField
                          fullWidth
                          id="bank_name"
                          label="Bank Name"
                          size="small"
                          value={instantBankDetails?.bank_name || ""}
                          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                          disabled
                        />
                        <TextField
                          fullWidth
                          id="ifsc_code"
                          label="IFSC"
                          size="small"
                          value={instantBankDetails?.ifsc_code || ""}
                          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                          disabled
                        />
                      </Box>
                    </>
                  )}
                </>
              ) : null}
              <FormControl fullWidth sx={{ mt: 3 }}>
                <TextField
                  id="notes"
                  label="Notes"
                  multiline
                  rows={3}
                  variant="outlined"
                  value={values.notes}
                  onChange={(e) => setFieldValue("notes", e.target.value)}
                  fullWidth
                />
              </FormControl>

              <Stack mt={3}>
                {settlementMode?.toLowerCase() === "weekly" && (
                  <>
                    <Typography variant="h4">NOTE:</Typography>
                    <Typography variant="body1">
                      Payments settle to your bank account WEEKLY on every
                      Friday, 10am.
                    </Typography>
                  </>
                )}
                {settlementMode?.toLowerCase() === "daily" && (
                  <>
                    <Typography variant="h4">NOTE:</Typography>
                    <Typography variant="body1">
                      Payments settle to your bank account DAILY at every
                      11:00AM.
                    </Typography>
                  </>
                )}
                {settlementMode?.toLowerCase() === "monthly" && (
                  <>
                    <Typography variant="h4">NOTE:</Typography>
                    <Typography variant="body1">
                      Payments settle to your bank account on every 28th day of
                      month.
                    </Typography>
                  </>
                )}
                {settlementMode?.toLowerCase() === "instant" ? (
                  <>
                    {orderData?.merchant_details?.process_mode?.delivery_otp ===
                      "y" &&
                    orderData?.order_status !==
                      ORDER_STATUS.PACKAGE_DELIVERED ? (
                      <>
                        <Typography variant="h4">NOTE:</Typography>
                        <Typography variant="body1">
                          Pharmacy opted for receiving payments after delivering
                          the order on successfull OTP validation. Please wait
                          or write an email on <b>techsupport@medpay.in</b>.
                        </Typography>
                      </>
                    ) : null}
                  </>
                ) : null}
              </Stack>

              <Box sx={{ mt: 3, textAlign: "center" }}>
                {(orderData?.invoice_details || uploadedFiles[0]) && (
                  <>
                    <PharmacyBills orderData={orderData} />
                    <Link
                      target="_blank"
                      href={
                        orderData.invoice_details
                          ? orderData.invoice_details.invoice_link
                          : uploadedFiles[0]
                      }
                      sx={{ textDecoration: "none" }}
                    >
                      <Button variant="outlined" color="primary" sx={{ mr: 1 }}>
                        View Invoice
                      </Button>
                    </Link>
                  </>
                )}
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={loading}
                  loadingIndicator={
                    <CircularProgress size={20} sx={{ color: "#fff" }} />
                  }
                  disabled={isSubmitting}
                >
                  Confirm payment
                </LoadingButton>
                {/* {TO_DO: Price difference of 10%} */}
              </Box>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default InstantPayment;
