import { Autocomplete, TextField } from "@mui/material";
import { sortBy } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { USER_ROLE } from "../../enums/common.enum";
import useUserInfo from "../../hooks/useUserInfo";
import { AuthenticatedUser } from "../../interfaces/user.model";

interface IPartnersACProps {
  providerId: string | null;
  setProviderId?: any;
  validate: string;
  setFieldValue?: any;
  errors?: any;
  touched?: any;
  handleBlur?: any;
  isCreateUser?: boolean;
  showAllOption?: boolean;
  disableField?: boolean;
  showOnlyActivePartners?: boolean;
}

const PartnersAutocomplete = (props: IPartnersACProps) => {
  const {
    providerId,
    setProviderId,
    validate,
    setFieldValue,
    errors,
    touched,
    handleBlur,
    isCreateUser,
    showAllOption,
    disableField,
    showOnlyActivePartners = false,
  } = props;
  let storedPartners = useSelector((state: any) => state.partners.partnersData);
  if (showOnlyActivePartners && storedPartners) {
    storedPartners = storedPartners?.filter(
      (partner: any) => partner.status === "active"
    );
  }
  let allPartners: Array<string> = [];
  storedPartners?.forEach((partner: any) =>
    allPartners.push(partner.partner_id)
  );
  allPartners = sortBy(allPartners);
  if (showAllOption) {
    allPartners = ["All", ...allPartners];
  }
  //PARTNER SPECIFIC: LOMBARD
  const location = useLocation();
  if (location.pathname?.toLowerCase().includes("create")) {
    allPartners = allPartners.filter(
      (partner: string) => partner?.toLowerCase() !== "lombard"
    );
  }
  //ALL PARTNERS
  const user: AuthenticatedUser = useUserInfo();
  if (user?.role === USER_ROLE.PARTNER) {
    allPartners = [`${user.partner}`];
  }
  return (
    <Autocomplete
      disabled={disableField}
      ListboxProps={{ style: { maxHeight: "14rem" } }}
      fullWidth
      size="small"
      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
      value={providerId}
      onChange={(e, val) => {
        validate === "true"
          ? setFieldValue("provider_id", val)
          : setProviderId(val);
        if (isCreateUser && val?.toLowerCase() !== "medpay") {
          setFieldValue("role", "partner");
        }
        //clear partner order id on change
        if (setFieldValue) {
          setFieldValue("partner_order_id", "");
        }
      }}
      onBlur={handleBlur}
      id="provider_id"
      options={allPartners}
      renderInput={(params) => (
        <TextField
          error={errors?.provider_id && touched?.provider_id ? true : false}
          helperText={
            errors?.provider_id && touched?.provider_id
              ? errors?.provider_id
              : ""
          }
          name="provider_id"
          label={validate === "true" ? "Partner *" : "Partner"}
          {...params}
        />
      )}
    />
  );
};

export default PartnersAutocomplete;
