import { combineReducers } from "redux";
import {
  editorsReducer,
  partnersReducer,
  userReducer,
  reportTimeReducer,
  orderDataReducer,
  searchReducer,
  notificationReducer,
} from "./useReducers";

const reducers = combineReducers({
  user: userReducer,
  partners: partnersReducer,
  editors: editorsReducer,
  reportTime: reportTimeReducer,
  order: orderDataReducer,
  search: searchReducer,
  notifications: notificationReducer,
});

export default reducers;
