import { createTheme } from "@mui/material";
import { addDays, format, formatDistance, parse } from "date-fns";
import _ from "lodash";
import { toast } from "react-toastify";
import {
  activateReasons,
  cancellationReasonsForConsultation,
  cancellationReasonsForDiagnostic,
  cancellationReasonsForHomeDel,
  cancellationReasonsForOPD,
  cancellationReasonsForPharmacy,
  deactivateReasons,
} from "../constants";
import { ORDER_STATUS, ORDER_TYPE } from "../enums/order.enum";

export const successToast = (message: string) => {
  toast(message, {
    type: toast.TYPE.SUCCESS,
    autoClose: 3000,
  });
};

export const errorToast = (message: string) => {
  toast(message, {
    type: toast.TYPE.ERROR,
    autoClose: 3000,
  });
};

export const infoToast = (message: string) => {
  toast(message, {
    type: toast.TYPE.INFO,
    autoClose: 1500,
  });
};

export const handleError = (error: any) => {
  if (!navigator.onLine) {
    errorToast("Please check your internet connection");
    return;
  }

  if (error.message) {
    if (error.message.toLowerCase().includes("payload validation")) {
      errorToast(error.message + ": " + JSON.stringify(error.errors));
    } else {
      errorToast(error.message);
    }
  }

  if (error.response) {
    switch (error.response.status) {
      case 400:
        errorToast(`${error.response.data.message}`);
        break;
      case 401:
        errorToast(`Session got expired. Please login again to continue!`);
        break;
      case 413:
      case 414:
        errorToast(`File size too large. Please upload a smaller file!`);
        break;
      case 415:
        errorToast(`Unsupported media type!`);
        break;
      case 429:
        errorToast(`Too many requests!`);
        break;
      default:
        errorToast(`${error.response.data.message}`);
        break;
    }
  }
};

export const validateIntNum = (val: any, limit: any) => {
  let value = val?.trim();
  if (!isNaN(Number(value)) && Number(value) < 0) {
    value = value.slice(1);
  }
  if (isNaN(Number(value)) || Number(value) > limit) {
    value = value.slice(0, -1);
  }
  value = value.includes(".") ? value.slice(0, -1) : value;

  return value;
};

export const validateFloatNum = (val: any, limit: any) => {
  let value = val?.trim();
  if (!isNaN(Number(value)) && Number(value) < 0) {
    value = value.slice(1);
  }
  if (isNaN(Number(value)) || Number(value) > limit) {
    value = value.slice(0, -1);
  }

  if (value.includes(".")) {
    const t = value.split(".");
    if (t[1].length > 2) {
      value = t[0] + "." + t[1].slice(0, -1);
    }
  }

  return value;
};

export const getCancellationOptions = (orderType: string) => {
  switch (orderType) {
    case "consultation":
      return cancellationReasonsForConsultation.sort();
    case "diagnostics":
      return cancellationReasonsForDiagnostic.sort();
    case "medicines":
      return cancellationReasonsForPharmacy.sort();
    case "opd":
      return cancellationReasonsForOPD.sort();
    case "home_delivery":
      return cancellationReasonsForHomeDel.sort();
    default:
      break;
  }
};

export const getReasons = (action: string) => {
  switch (action) {
    case "activate":
      return activateReasons.sort();
    case "deactivate":
      return deactivateReasons.sort();
    default:
      break;
  }
};

export const textCapitalize = (val: string) => {
  return val ? val.charAt(0).toUpperCase() + val.slice(1) : null;
};

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const getStatusMessage = (status: string) => {
  if (status === ORDER_STATUS.PENDING) {
    return "Order is yet to be accepted.";
  } else if (status === ORDER_STATUS.PARTNER_PENDING) {
    return "Order confirmation required.";
  } else if (status === ORDER_STATUS.ACCEPTED) {
    return "Order has been accepted.";
  } else if (status === ORDER_STATUS.CANCELLED) {
    return "This order has been cancelled as";
  } else if (status === ORDER_STATUS.INVOICE_GENERATED) {
    return "Invoice has been generated";
  } else if (status === ORDER_STATUS.PAYMENT_COLLECTED) {
    return "Payment received.";
  } else if (status === ORDER_STATUS.PACKAGE_PICKED) {
    return "Order has been picked up.";
  } else if (status === ORDER_STATUS.PACKAGE_DELIVERED) {
    return "Order has been delivered.";
  } else if (status === ORDER_STATUS.SAMPLE_COLLECTED) {
    return "Sample has been picked up.";
  } else if (status === ORDER_STATUS.REPORTS_GENERATED) {
    return "Reports have been generated.";
  } else if (status === ORDER_STATUS.BOOKING_CONFIRMED) {
    return "Booking has been confirmed.";
  } else if (status === ORDER_STATUS.BOOKING_COMPLETED) {
    return "Booking has been completed.";
  } else {
    return "";
  }
};

export const parseAndFormateDate = (value: string) => {
  return value
    ? format(
        parse(value.split(".")[0], "dd/MM/yyyy HH:mm:ss", new Date()),
        "dd MMM yyyy, hh:mm aaaaa'm'"
      )
    : null;
};

export const generateFullAddress = (value: any) => {
  return value
    ? `${value.address ? value.address + ", " : ""}${
        value.landmark ? value.landmark + ", " : ""
      }${value.city ? value.city + ", " : ""}${value.state ? value.state : ""}${
        value.pin_code ? " - " + value.pin_code : ""
      }`
    : "";
};

export const statusToText = (value: any) => {
  return value ? textCapitalize(value?.split("_").join(" ")) : null;
};

export const parseTimeslotToDateObj = (val: string) => {
  if (!val) return Number(0);
  const dateComp: any = val.split(", ")[0];
  const timeComp: any = val.split(", ")[1];
  const datePieces: any = dateComp.split("-");
  const timePieces: any = timeComp.split(" ");
  return new Date(
    datePieces[2],
    datePieces[1] - 1,
    datePieces[0],
    timePieces[1] === "PM"
      ? Number(timePieces[0].split(":")[0]) + 12
      : timePieces[0].split(":")[0],
    timePieces[0].split(":")[1],
    0
  );
};

export const isArrayEqual = function (x: any, y: any) {
  return _(x).xorWith(y, _.isEqual).isEmpty();
};

export const transformMobile = (val: string) => {
  return val ? val.slice(-10) : "";
};

export const transformAllOrder = (
  oldOrders: any,
  orderId: string,
  data: any
) => {
  let updateOrderData: any = [];
  oldOrders?.forEach((item: any) => {
    if (item.order_id === orderId) {
      updateOrderData = [...updateOrderData, data];
    } else {
      updateOrderData = [...updateOrderData, item];
    }
  });
  return updateOrderData;
};

export const removeTrailingSlash = (str: string) => {
  return str ? str.replace(/\/+$/, "") : "";
};

export const trimIfOnlySpaces = (str: string) => {
  return str?.length > 1 ? str : str?.trim();
};

export const shouldShowOrderData = (value: any) => {
  if (!value) return false;

  if (value?.order_type !== ORDER_TYPE.CONSULTATION) {
    if (value?.order_details) {
      return true;
    }
    return false;
  }

  if (value?.order_type === ORDER_TYPE.CONSULTATION) {
    if (value?.merchant_details) {
      return true;
    }
    return false;
  }
};

export const pendingStates = [
  "pending",
  "accepted",
  "partner_pending",
  "invoice_generated",
  "payment_collected",
  "package_picked",
  "sample_collected",
  "report_generated",
  "payment_pending",
  "booking_confirmed",
];

export const sortOrders = (orders: any) => {
  let today = new Date();
  let orderedOrders = [];
  let priorityOrders = [];

  //filter orders more than 2 days old and is in one of the pending state
  for (let i = orders.length - 1; i >= 0; i--) {
    let order = orders[i];
    let days = formatDistance(
      today,
      parse(order.created_at.split(".")[0], "dd/MM/yyyy HH:mm:ss", new Date())
    );
    if (days.includes("day") || days.includes("days")) {
      let number = Number.parseInt(days);
      if (number >= 2 && pendingStates.includes(order.order_status)) {
        orderedOrders.push(order);
      }
    }
  }

  //reverse orders to get latest at top
  orderedOrders = [...orderedOrders.reverse()];
  // push remaining orders to the list
  for (let i = 0; i < orders.length; i++) {
    let order = orders[i];
    if (orderedOrders.indexOf(order) === -1) {
      orderedOrders.push(order);
    }
  }

  //filter high priority orders
  for (let i = orderedOrders.length - 1; i >= 0; i--) {
    let order = orderedOrders[i];
    if (order.priority && order.priority === "high") {
      priorityOrders.push(order);
    }
  }
  //reverse
  priorityOrders = [...priorityOrders.reverse()];
  // push normal priority orders to list
  for (let i = 0; i < orderedOrders.length; i++) {
    let order = orderedOrders[i];
    if (priorityOrders.indexOf(order) === -1) {
      priorityOrders.push(order);
    }
  }

  //return in this seq: more than 2 days, high priority, normal priority
  return priorityOrders;
};

export const sortCashlessBills = (bills: any) => {
  let orderedBills = [];
  //filter processing bills at top
  for (let i = bills.length - 1; i >= 0; i--) {
    let bill = bills[i];
    if (bill.transaction_bill_status === "processing") {
      orderedBills.push(bill);
    }
  }
  //remaining bills
  for (const bill of bills) {
    if (orderedBills.indexOf(bill) === -1) {
      orderedBills.push(bill);
    }
  }
  return orderedBills;
};

export const getMuiTheme = () =>
  createTheme({
    components: {
      // @ts-ignore custom component
      MUIDataTableToolbar: {
        styleOverrides: {
          filterPaper: {
            width: "450px",
          },
        },
      },
    },
  });

export const calculatePercentage = (number: any, totalNumber: any) => {
  if (Number.parseFloat(totalNumber) === 0) {
    return 0;
  }
  return Number.parseFloat(
    ((Number.parseFloat(number) / Number.parseFloat(totalNumber)) * 100) as any
  ).toFixed(2);
};

export const toHoursAndMinutes = (totalMinutes: any) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return Number.parseFloat(String(hours + "." + minutes));
};

export const getCountByKey = (orders: any, key: any) => {
  let sum = 0;
  orders.forEach((order: any) => {
    sum += Number(order[key]);
  });
  return sum;
};

export const filterByKeyValue = (
  orders: any,
  key: string,
  value: string | number
) => {
  return orders.filter((x: any) => x[key] === value);
};

export const filterByKeyNotValue = (
  orders: any,
  key: string,
  value: string | number
) => {
  return orders.filter((x: any) => x[key] !== value);
};

export const generateGraphLabels = (startDate: any, endDate: any) => {
  let result = [];

  while (startDate <= endDate) {
    result.push(format(startDate, "yyyy-MM-dd"));
    startDate = addDays(startDate, 1);
  }
  //result.push(format(startDate, "yyyy-MM-dd"));
  return result;
};

export const toTitleCase = (str: string) => {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.replace(word[0], word[0].toUpperCase());
    })
    .join(" ");
};

export const wordify = (num: any) => {
  const single = [
    "Zero",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const double = [
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "",
    "Ten",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  const formatTenth = (digit: any, prev: any) => {
    return 0 === digit ? "" : " " + (1 === digit ? double[prev] : tens[digit]);
  };
  const formatOther = (digit: any, next: any, denom: any) => {
    return (
      (0 !== digit && 1 !== next ? " " + single[digit] : "") +
      (0 !== next || digit > 0 ? " " + denom : "")
    );
  };
  let res = "";
  let index = 0;
  let digit = 0;
  let next = 0;
  let words = [];
  if (((num += ""), isNaN(parseInt(num)))) {
    res = "";
  } else if (parseInt(num) > 0 && num.length <= 10) {
    for (index = num.length - 1; index >= 0; index--)
      switch (
        ((digit = num[index] - 0),
        (next = index > 0 ? num[index - 1] - 0 : 0),
        num.length - index - 1)
      ) {
        case 0:
          words.push(formatOther(digit, next, ""));
          break;
        case 1:
          words.push(formatTenth(digit, num[index + 1]));
          break;
        case 2:
          words.push(
            0 !== digit
              ? " " +
                  single[digit] +
                  " Hundred" +
                  (0 !== num[index + 1] && 0 !== num[index + 2] ? " and" : "")
              : ""
          );
          break;
        case 3:
          words.push(formatOther(digit, next, "Thousand"));
          break;
        case 4:
          words.push(formatTenth(digit, num[index + 1]));
          break;
        case 5:
          words.push(formatOther(digit, next, "Lakh"));
          break;
        case 6:
          words.push(formatTenth(digit, num[index + 1]));
          break;
        case 7:
          words.push(formatOther(digit, next, "Crore"));
          break;
        case 8:
          words.push(formatTenth(digit, num[index + 1]));
          break;
        case 9:
          words.push(
            0 !== digit
              ? " " +
                  single[digit] +
                  " Hundred" +
                  (0 !== num[index + 1] || 0 !== num[index + 2]
                    ? " and"
                    : " Crore")
              : ""
          );
      }
    res = words.reverse().join("");
  } else res = "";
  return res;
};

export function getDurationAgo(d: any, minus: number) {
  if (!d) return null;
  d = parse(d?.split(".")[0], "dd/MM/yyyy HH:mm:ss", new Date());
  d = Math.abs((new Date() as any) - d) / 1000;
  d = Number(d);
  d = Number(d) - Number(minus);

  var days = Math.floor(d / (3600 * 24));
  var h = Math.floor((d % (3600 * 24)) / 3600);
  var m = Math.floor((d % 3600) / 60);
  //var s = Math.floor((d % 3600) % 60);

  // var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
  // var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
  // var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";

  var dDisplay = days + "d ";
  var hDisplay = h + "h ";
  var mDisplay = m + "m ";
  //var sDisplay = s + "s ";

  //return hDisplay + mDisplay + sDisplay;
  return dDisplay + hDisplay + mDisplay;
}

export function transformDurationInMin(d: any) {
  if (!d) return 0;
  return (
    Number(d.split("d")[0] * 24 * 60) +
    Number(d.split("h")[0].split(" ")[1] * 60) +
    Number(d.split("m")[0].split(" ")[2])
  );
}

export function customReplaceAll(
  str: string,
  toReplace: string,
  replaceWith: string
) {
  if (!str) return "";
  return str.replace(new RegExp(toReplace, "g"), replaceWith)?.toLowerCase();
}

export const isUserAllowedToRevise = (status: string, role: string) => {
  if (status === "") {
    //create order
    return true;
  }
  //if payment collected check for role
  if (status?.toLowerCase() === ORDER_STATUS.PAYMENT_COLLECTED) {
    if (["admin", "super admin"].includes(role)) {
      return true;
    }
    return false;
  } else {
    return true;
  }
};

export const amountConverter = (val: number) => {
  if (!val) {
    return 0;
  }
  if (val > 9999999) {
    return `${Number(val / 10000000)?.toFixed(2)}Cr`;
  } else if (val > 99999) {
    return `${Number(val / 100000)?.toFixed(2)}L`;
  } else if (val > 999) {
    return `${Number(val / 1000).toFixed(2)}K`;
  } else {
    return Number(val)?.toFixed(2);
  }
};

export const getIssueStatusBgColor = (value: string) => {
  return value === "resolved"
    ? "#C6F6D5"
    : value === "on_hold"
    ? "#FFA500"
    : value === "in_process"
    ? "#FBD38D"
    : "#C53030";
};
