import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import LogoutIcon from "@mui/icons-material/Logout";
import { Outlet } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
import { Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { Badge } from "@mui/material";
import { Notifications } from "@mui/icons-material";
import CustomDialogTitle from "../Common/customDialogTitle";
import MedPayLogoBlack from "../../assets/images/medpayLogoBlack.png";
import Cookies from "js-cookie";
import SidebarMenu from "./sidebarMenu";
import RoleIcon from "@mui/icons-material/PersonPin";
import { useSelector } from "react-redux";

const drawerWidth = 245;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8.75)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#0059ff",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    backgroundColor: "#0059ff",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MiniDrawer: React.FC<{
  toggleNotification: (
    anchor: string,
    notifFlag: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  userId: string;
  userRole: string;
}> = ({ toggleNotification, userId, userRole }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [logoutModal, setLogoutModal] = React.useState(false);
  const [openIndex, setOpenIndex] = React.useState(
    Number(Cookies.get("openIndex")) || -1
  );
  const allNotifications = useSelector(
    (state: any) => state.notifications.data
  );
  const innerW = window.innerWidth;

  React.useEffect(() => {
    if (innerW < 768) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMenuClick = (i: number) => () => {
    if (i === openIndex) {
      setOpenIndex(-1);
    } else {
      setOpenIndex(i);
    }
  };

  React.useEffect(() => {
    Cookies.set("openIndex", String(openIndex));
  }, [openIndex]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = useLogout();
  const signOut = async () => {
    await logout();
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {userId}
          </Typography>
          <Box sx={{ position: "absolute", right: 15 }}>
            <Tooltip title="Notifications">
              <IconButton onClick={toggleNotification("right", true)}>
                <Badge
                  badgeContent={
                    isNaN(allNotifications?.length)
                      ? 0
                      : allNotifications?.length
                  }
                  color="error"
                >
                  <Notifications sx={{ color: "#fff" }} />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title={userRole}>
              <RoleIcon
                sx={{ position: "relative", top: "7px", marginLeft: 3 }}
              />
            </Tooltip>
            <Tooltip title="Logout">
              <IconButton
                onClick={() => setLogoutModal(true)}
                color="inherit"
                sx={{ marginLeft: 3 }}
              >
                <LogoutIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{ justifyContent: "space-around" }}>
          <img src={MedPayLogoBlack} alt="logo" style={{ width: "130px" }} />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <SidebarMenu
          open={open}
          handleMenuClick={handleMenuClick}
          openIndex={openIndex}
        />
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: { xs: 2, md: 3 }, overflowX: "hidden" }}
      >
        <DrawerHeader />
        <Outlet />
        <Dialog
          open={logoutModal}
          onClose={() => setLogoutModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
        >
          <CustomDialogTitle
            id="customized-dialog-title"
            onClose={() => setLogoutModal(false)}
          >
            {"Are you sure you want to Logout?"}
          </CustomDialogTitle>
          <DialogActions>
            <Button onClick={() => setLogoutModal(false)}>Cancel</Button>
            <Button onClick={signOut}>Ok</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default MiniDrawer;
