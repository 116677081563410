import React from "react";
import { Box, CircularProgress, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import OrderDetail from "../../../components/CreateOrder/orderDetail";
import PatientDetail from "../../../components/CreateOrder/patientDetail";
import AddressDetail from "../../../components/CreateOrder/addressDetail";
import { LoadingButton } from "@mui/lab";
import UploadFiles from "../../../components/Common/uploadFiles";
import SearchAutoComplete from "../../../components/CreateOrder/searchAutoComplete";
import MedicinesTable from "./medicinesTable";
import PartnerIdChange from "../../../components/CreateOrder/partnerIdChange";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import {
  errorToast,
  handleError,
  removeTrailingSlash,
  successToast,
} from "../../../utils/common";
import { ORDER_TYPE } from "../../../enums/order.enum";
import { createPharmacySchema } from "../../../schemas/pharmacy";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";

const CreatePharmacyOrder = () => {
  const [loading, setLoading] = React.useState(false);
  const [prescriptionUrls, setPrescriptionUrls] = React.useState([]);
  const [selectedMedicines, setSelectedMedicines] = React.useState<Array<any>>(
    []
  );
  const [selectedMedicinesId, setSelectedMedicinesId] = React.useState<
    Array<string>
  >([]);
  const [medTableError, setMedTableError] = React.useState<any>([]);
  const [appliedDiscountPercent, setAppliedDiscountPercent] = React.useState(0);

  const privateInstance = useAxiosInstance();

  const handleSubmit = async (values: any, actions: any) => {
    if (prescriptionUrls.length === 0) {
      alert("Please upload prescription");
      return;
    }
    if (selectedMedicines.length === 0) {
      alert("Please add at least one medicine to proceed");
      return;
    }

    let formError = false;
    //meds table error alert
    medTableError.forEach((x: any) => {
      if (x.mrp || x.pack_quantity || x.quantity) {
        formError = true;
      }
    });

    if (formError) {
      alert("Please correct all errors to proceed");
      return;
    }

    let medicinesAfterExtraKeyRemoved: any = [];
    selectedMedicines.forEach((medicine: any) => {
      if (medicine.change_type) delete medicine.change_type;
      if (medicine.db_mrp) delete medicine.db_mrp;
      medicinesAfterExtraKeyRemoved.push(medicine);
    });

    let payload: any = {
      customer_details: {
        address_details: {
          address: removeTrailingSlash(
            values.address?.trim().replace("\t", "")
          ),
          city: values.city,
          landmark: removeTrailingSlash(
            values.landmark?.trim().replace("\t", "")
          ),
          pin_code: removeTrailingSlash(values.pin?.trim().replace("\t", "")),
          state: values.state,
        },
        alternative_mobile: removeTrailingSlash(
          values.alt_mobile?.trim().replace("\t", "")
        ),
        email: removeTrailingSlash(values.email?.trim().replace("\t", "")),
        mobile: removeTrailingSlash(values.mobile?.trim().replace("\t", "")),
        name: removeTrailingSlash(values.full_name?.trim().replace("\t", "")),
      },
      delivery_partner: values.delivery_partner,
      order_partner: values.provider_id,
      order_type: values.order_type,
      partner_order_id: removeTrailingSlash(
        values.partner_order_id?.trim().replace("\t", "")
      ),
      payment_collection: values.payment_collection,
      order_details: {
        doctor: removeTrailingSlash(values.doctor?.trim().replace("\t", "")),
        items: medicinesAfterExtraKeyRemoved,
        prescription_links: prescriptionUrls,
      },
    };

    if (appliedDiscountPercent > 0) {
      payload = {
        ...payload,
        applied_discount: appliedDiscountPercent,
      };
    }

    setLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/create-order`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Order created successfully");
        actions.resetForm({});
        setSelectedMedicines([]);
        setPrescriptionUrls([]);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        px: 3,
        py: 2,
        boxShadow:
          "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      }}
    >
      <Formik
        initialValues={{
          provider_id: "" || null,
          partner_order_id: "",
          order_type: "medicines",
          delivery_partner: "medpay",
          payment_collection: "self",
          consultation_type: "",
          full_name: "",
          mobile: "",
          alt_mobile: "",
          email: "",
          address: "",
          landmark: "",
          pin: "",
          city: "",
          state: "",
          doctor: "",
        }}
        validationSchema={createPharmacySchema}
        onSubmit={(values: any, actions: any) => {
          actions.setSubmitting(false);
          handleSubmit(values, actions);
        }}
      >
        {(props: any) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            isSubmitting,
            setFieldValue,
            setTouched,
          } = props;
          return (
            <Form>
              <Typography variant="h4">Order Details:</Typography>
              <OrderDetail
                createOrderType={ORDER_TYPE.MEDICINES}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                formData={values}
              />
              <Typography variant="h4" sx={{ mt: 3 }}>
                Customer Details:
              </Typography>
              <PatientDetail
                createOrderType={ORDER_TYPE.MEDICINES}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                formData={values}
              />
              <Typography variant="h4" sx={{ mt: 3 }}>
                Address Details:
              </Typography>
              <AddressDetail
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                formData={values}
                setTouched={setTouched}
                serviceabilityCheck={true}
              />
              <Box
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                justifyContent="space-between"
                sx={{ mt: 1.5 }}
              >
                <TextField
                  id="doctor"
                  label="Doctor Name"
                  size="small"
                  value={values.doctor || ""}
                  onChange={(e) => setFieldValue("doctor", e.target.value)}
                  sx={{
                    mr: { xs: 0, md: 1 },
                    width: { xs: "100%", md: "30%" },
                  }}
                  onBlur={handleBlur}
                />
                <Box
                  width={{ xs: "100%", md: "70%" }}
                  sx={{ ml: { xs: 0, md: 5 } }}
                >
                  <UploadFiles
                    title="Upload Prescription *"
                    uploadedFiles={prescriptionUrls}
                    setUploadedFiles={setPrescriptionUrls}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                }}
              ></Box>

              <Box>
                <SearchAutoComplete
                  sx={{ my: 5 }}
                  selectedValues={selectedMedicines}
                  setSelectedValues={setSelectedMedicines}
                  selectedValuesId={selectedMedicinesId}
                  setSelectedValuesId={setSelectedMedicinesId}
                  autocompleteLabel="Medicines"
                />
                <MedicinesTable
                  data={selectedMedicines}
                  setSelectedMedicines={setSelectedMedicines}
                  selectedMedicinesId={selectedMedicinesId}
                  setSelectedMedicinesId={setSelectedMedicinesId}
                  isEditing={false}
                  orderMerchantMeta={[]}
                  orderItems={[]}
                  medTableError={medTableError}
                  setMedTableError={setMedTableError}
                  orderPartner={values?.provider_id}
                  appliedDiscountPercent={appliedDiscountPercent}
                  setAppliedDiscountPercent={setAppliedDiscountPercent}
                />
              </Box>

              <Box textAlign="center" sx={{ mt: 3 }}>
                <LoadingButton
                  sx={{ width: "180px" }}
                  size="medium"
                  color="primary"
                  loading={loading}
                  loadingIndicator={
                    <CircularProgress size={20} sx={{ color: "#fff" }} />
                  }
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </LoadingButton>
              </Box>
              <PartnerIdChange setFieldValue={setFieldValue} />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default CreatePharmacyOrder;
