import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import { sortBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import useUserInfo from "../../hooks/useUserInfo";
import { AuthenticatedUser } from "../../interfaces/user.model";
import { agentDataSchema } from "../../schemas/common";
import { errorToast, handleError, successToast } from "../../utils/common";

const allTeams = ["p", "c", "d", "whatsapp_leads", "app_issues"];
const allLangugaes = [
  "hindi",
  "english",
  "punjabi",
  "gujarati",
  "marathi",
  "kannada",
  "tamil",
  "telugu",
  "oriya",
  "malayalam",
  "bengali",
];

const EditAgentDataModal = ({
  editAgentModal,
  setEditAgentModal,
  agentData,
  getAgentsData,
}: any) => {
  const [initVal, setInitVal] = useState<any>({
    manager_id: "",
    team: "",
    availability: "",
    languages: "",
    partners: "",
  });
  const [loading, setLoading] = useState(false);
  const user: AuthenticatedUser = useUserInfo();
  const privateInstance = useAxiosInstance();

  const handleSubmit = async (values: any, actions: any) => {
    let payload = {
      agent_id: agentData?.agent_id,
      available: values?.availability,
      languages: values?.languages,
      manager_id: values?.manager_id,
      team: values?.team,
      role: user?.role,
      partners: values?.partners,
    };

    if (payload.team.length === 0) {
      alert("Please select at least one team");
      return;
    }

    if (payload.languages.length === 0) {
      alert("Please select at least one language");
      return;
    }

    setLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/agents`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Agent updated successfully");
        getAgentsData();
        setEditAgentModal(false);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setInitVal({
      ...initVal,
      manager_id: agentData?.manager_id,
      team: agentData?.team,
      availability: agentData?.available,
      languages: agentData?.languages,
      partners: agentData?.partners,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const storedPartners = useSelector(
    (state: any) => state.partners.partnersData
  );
  let allPartners: Array<string> = [];
  storedPartners?.forEach((partner: any) =>
    allPartners.push(partner.partner_id)
  );
  allPartners = sortBy(["All", ...allPartners]);

  return (
    <Dialog
      open={editAgentModal}
      onClose={() => setEditAgentModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setEditAgentModal(false)}
      >
        Update Agent
      </CustomDialogTitle>
      <DialogContent>
        <Formik
          initialValues={initVal}
          enableReinitialize={true}
          onSubmit={(values: any, actions: any) => {
            actions.setSubmitting(false);
            handleSubmit(values, actions);
          }}
          validationSchema={agentDataSchema}
        >
          {(props: any) => {
            const {
              values,
              errors,
              touched,
              handleBlur,
              isSubmitting,
              setFieldValue,
            } = props;
            return (
              <Form>
                <Stack direction="row" mt={1.5}>
                  <TextField
                    fullWidth
                    id="agent-id"
                    label="Agent ID"
                    size="small"
                    value={agentData?.agent_id || ""}
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    disabled
                  />
                  <TextField
                    fullWidth
                    id="mobile"
                    label="Role"
                    size="small"
                    value={agentData?.role || ""}
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    disabled
                  />
                </Stack>
                <Stack direction="row" mt={1.5}>
                  <TextField
                    fullWidth
                    required
                    id="manager_id"
                    label="Manager ID"
                    size="small"
                    value={values?.manager_id || ""}
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    onChange={(e: any) =>
                      setFieldValue("manager_id", e.target.value)
                    }
                    onBlur={handleBlur}
                    helperText={
                      errors.manager_id && touched.manager_id
                        ? errors.manager_id
                        : ""
                    }
                    error={
                      errors.manager_id && touched.manager_id ? true : false
                    }
                  />
                  <Autocomplete
                    filterSelectedOptions
                    multiple
                    fullWidth
                    size="small"
                    id="all_teams"
                    options={allTeams}
                    getOptionLabel={(option: any) => option}
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Team *"
                        variant="outlined"
                        size="small"
                      />
                    )}
                    value={values?.team || []}
                    onChange={(e, val: any) => {
                      setInitVal({
                        ...initVal,
                        team: val,
                      });
                    }}
                  />
                </Stack>
                <Stack direction="row" mt={1.5}>
                  <FormControl
                    size="small"
                    fullWidth
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    error={
                      errors.confirmation_type && touched.confirmation_type
                        ? true
                        : false
                    }
                  >
                    <InputLabel required id="availability-label">
                      Availability
                    </InputLabel>
                    <Select
                      labelId="availability-label"
                      name="availability"
                      id="availability"
                      value={values.availability}
                      label="Availability"
                      onChange={(e: SelectChangeEvent) => {
                        setFieldValue("availability", e.target.value);
                      }}
                      onBlur={handleBlur}
                    >
                      <MenuItem value="y">Y</MenuItem>
                      <MenuItem value="n">N</MenuItem>
                    </Select>
                    {errors.availability && touched.availability && (
                      <FormHelperText>{errors.availability}</FormHelperText>
                    )}
                  </FormControl>
                  <Autocomplete
                    filterSelectedOptions
                    multiple
                    fullWidth
                    size="small"
                    id="all_languages"
                    options={allLangugaes}
                    getOptionLabel={(option: any) => option}
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Languages *"
                        variant="outlined"
                        size="small"
                      />
                    )}
                    value={values?.languages || []}
                    onChange={(e, val: any) => {
                      setInitVal({
                        ...initVal,
                        languages: val,
                      });
                    }}
                  />
                </Stack>
                <Stack direction="row" mt={1.5}>
                  <Autocomplete
                    filterSelectedOptions
                    multiple
                    fullWidth
                    size="small"
                    id="partners"
                    options={allPartners}
                    getOptionLabel={(option: any) => option}
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Partners *"
                        variant="outlined"
                        size="small"
                      />
                    )}
                    value={values?.partners || []}
                    onChange={(e, val: any) => {
                      setInitVal({
                        ...initVal,
                        partners: val,
                      });
                    }}
                  />
                </Stack>
                <Box
                  style={{ display: "flex", justifyContent: "center" }}
                  mt={4}
                >
                  <LoadingButton
                    sx={{ width: "180px" }}
                    size="medium"
                    color="primary"
                    loading={loading}
                    loadingIndicator={
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    }
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Update
                  </LoadingButton>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default EditAgentDataModal;
